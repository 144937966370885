import './BoughtTicket.css';
import blue from '../../assets/blue.png';
import { Link } from 'react-router-dom';

const BoughtTicket = () => {
    return (
        <div class='flex justify-center mt-12'>
            <div className='grid justify-items-center w-[1000px]'>
                <div className='background22'>
                    <div className='border border-grey border-opacity-30 w-[477px] h-[369px] ml-[310px] mt-40 bg-white'>
                        <div class='flex justify-center mt-9'><img alt='img' src={blue} width='86px' /></div>
                        <div className='text-[42px] text-center text-hover mt-7'>Congratulations!</div>
                        <div className='text-[30px] text-center text-grey mt-4'>Ticket Bought Successfully.</div>
                        <div className='flex justify-center'>
                            <Link to='/User-Profile' className='text-[20px] text-white text-center bg-binance hover:bg-hover w-[430px] hover:text-binance py-3 mt-8'>Rush to profile</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default BoughtTicket;