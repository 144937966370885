import image from "../../assets/token.png";
import image2 from "../../assets/img2.png";
const Section2 = ({ web3Handler }) => {
    
    return (
        <div class='flex justify-center'>
            <div class=" py-1 pt-9 md:pt-24 pt-36">

                <div class='text-hover md:text-3xl text-xl pt-9 mb-9 text-center flex justify-center'>Connect your wallet <br /> to check if you have won</div>
                <div class='flex justify-center '><img src={image} alt='tickets' class='md:h-32 h-24 md:w-32' /></div>
                <div class='pt-9 flex justify-center'>
                    <button onClick={web3Handler} class='hover:text-[#3ABFF8] bg-binance hover:bg-hover py-3 w-56 font-medium text-white'>
                        Connect Wallet
                    </button>
                </div>
                <div class='flex justify-center mt-9'><img src={image2} alt='img' width='805px' /></div>
            </div>

        </div>
    );
};

export default Section2;