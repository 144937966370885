import Box from "./Box";
import Section2 from "./Section2";
import Section3 from "./Section3";
import img from '../../assets/one.svg'
import './Home.css'
import { useEffect, useState } from "react";
import DisplayMessage from "../Alert/DisplayAlert";
// import { useNavigate } from "react-router-dom";
// import { useState } from "react";
const targetTime = new Date("2023-2-10").getTime();

const Home = ({ web3Handler, account, indexxFortuneRush, switchNetwork }) => {
  const [currentTime, setCurrentTime] = useState(Date.now());
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("Oops!")
  const [msg, setMsg] = useState("");

  const [time, setTime] = useState({
    timeBetween: '',
    minutes: '30',
    hours: '2',
    days: '4',
  })
  // const timeBetween = targetTime - currentTime;
  // const minutes = Math.floor((timeBetween / 1000 / 60) % 60);
  // const hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
  // const days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));



  const calcTime = async () => {
    // console.log("incalcTime:");
    let timeBetween, minutes, hours, days;
    if (indexxFortuneRush.signer) {
      let currentTime, targetTime;
      try {
        currentTime = await indexxFortuneRush.viewCurTime();
        const lotteryId = localStorage.getItem('lotteryId');
        targetTime = await indexxFortuneRush.viewLottery(lotteryId);
        targetTime = parseInt(targetTime[2].toString(), 10);
        timeBetween = targetTime - currentTime;
        days = Math.floor(timeBetween / (3600 * 24));
        timeBetween = timeBetween % (24 * 3600);
        hours = Math.floor(timeBetween / (60 * 60));
        timeBetween %= 3600;
        minutes = Math.floor(timeBetween / 60);
      } catch (error) {
        console.log(error);
      }
    } else {
      timeBetween = targetTime - currentTime;
      minutes = Math.floor((timeBetween / 1000 / 60) % 60);
      hours = Math.floor((timeBetween / (1000 * 60 * 60)) % 24);
      days = Math.floor(timeBetween / (1000 * 60 * 60 * 24));
    }

    if (minutes < 0) {
      minutes = hours = days = 0;
    }
    setTime({
      timeBetween: timeBetween,
      minutes: minutes,
      hours: hours,
      days: days,
    })

  }
  const handleSwitch = async () => {
    try {
      let x = await switchNetwork();
      if (x === 0) {
        setMsg("We are currently available on BSC test network only. Kindly switch to Binance Smart Chain (BSC) test network.");
        setTitle("Oops!");
        setIsModalOpen(true);
        // x = await switchNetwork();
        // return;
      }
    } catch (err) {

    }
  }
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(Date.now());
      calcTime();
      handleSwitch();
    }, 10000);

    return () => clearInterval(interval);

  });
  return (
    <div class='overflow-x-hidden'>
      <div>
        <div class='min-w-full min-h-full '><img alt="img" className="bg3 w-full h-full" src={img} ></img></div>
        <div class='md:text-5xl text-xl flex justify-center font-bold text-hover mb-40 mt-9'>Winning Every Hour</div>
        <div class='md:text-5xl text-xl pt-6 flex justify-center font-bold pb-5 text-hover'>Get Your Tickets Now!</div>
        <div class='flex justify-center pt-4 text-grey text-white'>
          {/* <a href="/successfully-bought-ticket" className="bg-grey text-white text-3xl px-4 py-3">buyyy</a> */}
          <div class='grid justify-items-center w-[550px]'>
            <div className="background">
              <div class='ml-44 text-white mt-5'>Next Draw Remaining Time</div>
              <div class='inline-flex ml-[175px]'>
                <span class='md:text-[44px] text-[24px] text-white font-bold tracking-[10px]'><div>{time.days}</div><div class='text-xs text-center font-normal tracking-normal'>Days</div></span>
                <span class='md:text-[34px] text-[14px] text-white pt-3 mx-[10px] font-medium'>:</span>
                <span class='md:text-[44px] text-[24px] text-white font-bold tracking-[10px]'><div>{time.hours}  </div><div class='text-xs text-center font-normal tracking-normal'>Hours</div></span>
                <span class='md:text-[34px] text-[14px] text-white pt-3 mx-[10px] font-medium'>:</span>
                <span class='md:text-[44px] text-[24px] text-white font-bold tracking-[10px]'><div>{time.minutes} </div><div class='tracking-normal text-xs text-center font-normal'>Minutes</div></span>

              </div>
            </div>
          </div>
        </div>

        <Box account={account} indexxFortuneRush={indexxFortuneRush} />
        <Section2 web3Handler={web3Handler} switchNetwork={switchNetwork} />
        <Section3 indexxFortuneRush={indexxFortuneRush} />
      </div>
      <DisplayMessage title={title} isVisible={isModalOpen} message={msg} onClose={() => setIsModalOpen(false)} />

    </div>
  );
};

export default Home;