import { BrowserRouter, Route, Routes } from "react-router-dom";
import { useState } from "react";
import { ethers } from 'ethers';
import { isChrome, isEdge, isFirefox, isIE, isOpera } from "react-device-detect";

import './App.css';
import Home from "./components/Home/Home";
import Buy from "./components/Buy/Buy";
import Header from "./components/Header/Header";
import UserProfile from "./components/UserProfile/UserProfile";
import Footer from "./components/Footer/Footer";
import IndexxFortuneRushAddress from './contractsData/IndexxFortuneRush-address.json';
import IndexxFortuneRushAbi from './contractsData/IndexxFortuneRush.json';
import DisplayMessage from "./components/Alert/DisplayAlert";
import EditNumbers from "./components/ViewNumbers/EditNumbers";
import Congrats from "./components/UserProfile/Congrats";
import BoughtTicket from "./components/Buy/BoughtTicket";
import HowToPlay from "./components/HowToPlay/HowToPlay";
import ScrollToTop from "./ScrollToTop";



const App = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [title, setTitle] = useState("Oops!")
  const [msg, setMsg] = useState("");
  const [account, setAccount] = useState(null);
  const [indexxFortuneRush, setIndexxFortuneRush] = useState({});
  const [provider, setProvider] = useState();
  const [buttonText, setButtonText] = useState("Ok");
  const [navigation, setNavigation] = useState("");

  const findBrowserAndSetNavigation = () => {
    // console.log("Browser: ");
    // console.log(browserName, browserVersion);
    // console.log(isFirefox, isEdge, isSafari, isChrome, isIE);
    if (isFirefox)
      setNavigation("https://addons.mozilla.org/en-US/firefox/addon/ether-metamask/");
    else if (isEdge || isIE)
      setNavigation("https://microsoftedge.microsoft.com/addons/detail/metamask/ejbalbakoplchlghecdalmeeeajnimhm?hl=en-US");
    else if (isChrome)
      setNavigation("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn")
    else if (isOpera)
      setNavigation("https://addons.opera.com/en-gb/extensions/details/metamask-10/");
    // else if(isBrave)
    else
      setNavigation("https://chrome.google.com/webstore/detail/metamask/nkbihfbeogaeaoehlefnkodbefgpgknn");
  }
  const handleSwtichNetwork = async () => {
    console.log("in swtich net");
    try {
      let chainId = await window.ethereum.request({ method: 'eth_chainId' });
      chainId = parseInt(chainId.toString(), 16);
      console.log("Chain id: ", chainId)
      if (chainId !== 97) {
        // setIsModalOpen(true);
        await window.ethereum.request({
          method: "wallet_addEthereumChain",
          params: [
            {
              chainId: "0x61",
              chainName: "BSC Testnet",
              rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
              blockExplorerUrls: [
                "https://explorer.binance.org/smart-testnet",
              ],
              nativeCurrency: {
                symbol: "BNB",
                decimals: 18,
              },
            },
          ],
        });
      }
      chainId = await window.ethereum.request({ method: 'eth_chainId' });
      chainId = parseInt(chainId.toString(), 16);
      console.log("Chain id: ", chainId)
      if (chainId !== 97) {
        console.log("xxx");
        // setMsg("We are currently available on BSC network only. Kindly switch to Binance Smart Chain (BSC) network.");
        // setTitle("Oops!");
        // setIsModalOpen(true);
        // switchNetwork();
        return 0;
      }
      return 1;
    } catch (err) {
      console.log(err);
      return 0;
    }

  }
  const switchNetwork = async () => {
    try {
      const x = await handleSwtichNetwork();
      if (x === 0) {
        // setMsg("We are currently available on BSC network only. Kindly switch to Binance Smart Chain (BSC) network.");
        // setTitle("Oops!");
        // setIsModalOpen(true);
        return 0;
      }
      else return 1;

    } catch (err) {
      console(err);
    }
  }
  const web3Handler = async () => {
    console.log(window.ethereum)
    if (window.ethereum && window.ethereum.isMetaMask && !window.ethereum.isBraveWallet) {

      let chainId;
      try {
        chainId = await window.ethereum.request({ method: 'eth_chainId' });
        chainId = parseInt(chainId.toString(), 16);
        console.log("Chain id: ", chainId)
        if (chainId !== 97) {
          // setIsModalOpen(true);
          await window.ethereum.request({
            method: "wallet_addEthereumChain",
            params: [
              {
                chainId: "0x61",
                chainName: "BSC Testnet",
                rpcUrls: ["https://data-seed-prebsc-1-s1.binance.org:8545"],
                blockExplorerUrls: [
                  "https://explorer.binance.org/smart-testnet",
                ],
                nativeCurrency: {
                  symbol: "BNB",
                  decimals: 18,
                },
              },
            ],
          });
          // return;
        }
        const accounts = await window.ethereum.request({
          method: 'eth_requestAccounts',
        });



        setAccount(accounts[0]);
        // Get provider from Metamask
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        setProvider(provider);
        // Set signer
        const signer = provider.getSigner();

        window.ethereum.on('chainChanged', (chainId) => {
          // window.location.reload();
        });

        window.ethereum.on('accountsChanged', async function (accounts) {
          // window.location.reload();
          // console.log("Reloaded");
          setAccount(accounts[0]);

          await web3Handler();
        });
        loadContracts(signer);
      } catch (err) {
        console.log(err);
      }
    } else {
      console.log("no metamask");
      // displayMessage("Oops!", "Metamask is not installed or is conflicted with other wallets",0);
      setMsg("Metamask is not installed or is conflicted with other wallets");
      setTitle("Oops!");
      setIsModalOpen(true);
      findBrowserAndSetNavigation();
      setButtonText("Install Metamask");
      // swal("Metamask not installed or conflicted with other wallets");
      return;
    }
    // console.log('Acc :', account);
    // swal('Successfully connected', '', 'success');
    // displayMessage("Hurray!", "Successfully connected", 1);
    setTitle("Hurray!");
    setMsg("Successfully connected");
    setIsModalOpen(true);
  };
  const loadContracts = async (signer) => {
    console.log('in load Contract');

    const indexxFortuneRush = new ethers.Contract(
      IndexxFortuneRushAddress.address,
      IndexxFortuneRushAbi.abi,
      signer
    );
    setIndexxFortuneRush(indexxFortuneRush);

    console.log('Loaded..', indexxFortuneRush, account);
    let lotteryId = await indexxFortuneRush.viewCurrentLotteryId();
    lotteryId = parseInt(lotteryId.toString(), 10);
    localStorage.setItem('lotteryId', lotteryId);
    console.log("LotteryId", localStorage.getItem('lotteryId'));

  };
  return (
    <>
      <div>

        <BrowserRouter>
          <ScrollToTop />
          <Header web3Handler={web3Handler} account={account} indexxFortuneRush={indexxFortuneRush} />
          <Routes>
            <Route path="/*" element={<Home web3Handler={web3Handler} account={account} indexxFortuneRush={indexxFortuneRush} switchNetwork={switchNetwork} />} />
            <Route path="/Buy" element={<Buy web3Handler={web3Handler} account={account} indexxFortuneRush={indexxFortuneRush} switchNetwork={switchNetwork} />} />
            <Route path="/User-Profile" element={<UserProfile web3Handler={web3Handler} account={account} indexxFortuneRush={indexxFortuneRush} provider={provider} switchNetwork={switchNetwork} />} />
            <Route path="/View-Edit-Numbers" element={<EditNumbers indexxFortuneRush={indexxFortuneRush} account={account} provider={provider} switchNetwork={switchNetwork} />} />
            <Route path="/Rewards" element={<Congrats indexxFortuneRush={indexxFortuneRush} account={account} provider={provider} />} />
            <Route path="/successfully-bought-ticket" element={<BoughtTicket indexxFortuneRush={indexxFortuneRush} account={account} provider={provider} />} />
            <Route path="/how-to-play" element={<HowToPlay indexxFortuneRush={indexxFortuneRush} account={account} provider={provider} />} />
          </Routes>
          <Footer />
        </BrowserRouter>
      </div>
      <DisplayMessage title={title} isVisible={isModalOpen} message={msg} onClose={() => setIsModalOpen(false)} navigation={navigation} buttonText={buttonText} />
    </>
  )
};

export default App;