import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import img2 from '../../assets/img1.png';
import DisplayMessage from '../Alert/DisplayAlert';

const Box = ({ account, indexxFortuneRush }) => {
    const[isModalOpen, setIsModalOpen] =useState(false);
    const[title,setTitle]=useState("Oops!")
    const[msg,setMsg]=useState("");
    const [prizePot, setPrizePot] = useState(101899);
    const navigate = useNavigate();
    const handleSubmit = async (e) => {
        if (account != null) {
            navigate('/Buy');
        } else {
            // setIsModalOpen(true);
            //displayMessage("Oops!", "Kindly Connect wallet prior to buying the ticket", 0);
            setMsg("Kindly connect wallet prior to buying the ticket");
            setTitle("Oops!");
            setIsModalOpen(true);
        }
    }
    const calculatePrizePot = async () => {
        let  lotteryId = localStorage.getItem('lotteryId');
        // let lotteryId = await indexxFortuneRush.viewCurrentLotteryId();
        lotteryId = parseInt(lotteryId.toString(), 10);
        let amount = await indexxFortuneRush.viewLottery(lotteryId);
        amount = parseInt(amount[11].toString(), 10);
        amount /= 1e18;
        console.log("Amount in INEX: ", amount, amount * 0.2);
        amount *=0.2;
        amount = amount.toFixed(2);

        setPrizePot(amount);
        localStorage.setItem('prizePot', amount);
    }
    useEffect(() => {
        const interval = setInterval(() => {
            if (indexxFortuneRush.signer)
                calculatePrizePot();
        }, 1000);

        // don't forget to do clean up
        return () => clearInterval(interval);
    })
    return (
        <div className="flex justify-center">
            <div>
                <div class="flex justify-center font-normal md:text-sm text-hover text-[10px]">#676 | Draw: Oct 21, 2022, 5:00 PM</div>


                <div className='flex justify-center text-hover'>
                    <div class="font-normal flex  p-4 md:text-5xl text-xs">Prize Pot</div>
                    <div class="col-start-3 col-span-3 p-4 md:text-5xl text-xl md:font-bold font-semibold">~${prizePot}</div>

                </div>
                <div></div>
                <div class='mt-4 flex justify-center'><img src={img2} alt='tickets' class='md:w-[390px] w-40 '></img></div>
                <div class='mt-28 flex justify-center  text-hover text-4xl'>Decentralized lottery powered by Blockchain</div>
                <div class='my-9 flex justify-center '>
                    <button onClick={handleSubmit} class='bg-binance hover:text-[#3ABFF8] font-medium text-white hover:bg-hover py-1 w-16 md:py-3 md:w-56' >
                        Buy Tickets
                    </button>

                </div>

            </div>
            <DisplayMessage title={title} isVisible={isModalOpen} message={msg} onClose={() => setIsModalOpen(false)} />
        </div>

    );
}

export default Box;