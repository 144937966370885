import img1 from '../../assets/top.png'
import img2 from '../../assets/second.png'
import img3 from '../../assets/third.png'
import img4 from '../../assets/fourth.png'
import ticket from '../../assets/tickett.png'
import pp from '../../assets/pp.png'
import edit from '../../assets/htpr2.png'
import image from "../../assets/tokenimg.png";
import { Tabs } from 'antd'

const HowToPlay = () => {
    return (
        <div class='text-grey mt-12 flex justify-center'>
            <div>
                <div class='text-[30px] font-medium'>How to Play Fortune Rush </div>
                <div class='mt-12 flex justify-center'><img src={img1} alt='img1' width='1023px' /></div>
                <div class='text-[20px] w-[1145px] mt-12'>Fortune Rush Lottery is not only a good way to make stacks of INEX, it's also loads of fun! This guide will walk you through getting involved in the Lottery.</div>
                <div class='flex justify-center w-[1113px] mt-20'><img src={img2} alt='img2' /></div>
                <div class='text-[30px] w-[1145px] mt-12'>Deciding if you'd like to enter a round</div>
                <div class='text-[20px] w-[1145px] mt-6'>
                    ‌Not all Lottery rounds are equal. When a lottery jackpot isn't won, the next round's prize pool will increase; the longer it's been since someone has won the jackpot, the larger the Lottery prize pool will be.
                    ‌The longer a round is open, the more people can buy a ticket. Each ticket sold increases the prize pool further. Here's how to check the current round's prize pool and remaining time.
                    <br /><br />
                    ‌1. Visit the Fortune Rush and look at the top of the page. You'll see the current round's prize pool shown in (estimated) US dollar value.
                </div>
                <div class='flex justify-center mt-5'><img src={img3} alt='img' /></div>
                <div class='text-[20px] w-[1145px] mt-6'>
                    This is the amount to be shared between all winners.
                    <br /><br />‌2. A little lower down the page you'll find the remaining time before the current round is drawn.
                </div>
                <div class='flex justify-center my-5'><img src={img4} alt='img4' /></div>
                <div class='text-[20px] w-[1145px] mt-6'>
                    Remember, a lot can change in the remaining time. Many more tickets may be sold. Each sold ticket both increases the prize pool, and lowers the odds of an individual ticket winning.
                    <br /><br />‌<br />If you're unsure, you can always wait until later into the round to decide with more information. Just don't wait too long and miss out!
                </div>
                <div class='text-[30px] w-[1145px] mt-12'>Buying Lottery tickets</div>
                <div class='text-[20px] w-[1145px] mt-6'>
                    Buying tickets is easy.<br />
                    You just need a little INEX tokens and Binance coin. INEX token are required to buy tickets. Each ticket costs approximately $5 USD (the exact INEX price is set at the start of the round). Binance coins (BNB) are required to pay for the transaction fees.
                    <br /><br />
                    You can choose to either randomize your lottery ticket numbers, or to choose your ticket numbers manually. Choose between the randomized numbers and manual numbers tabs below for a guide to each method.
                </div>
                <div class='my-9 border border-opacity-10 border-grey pl-3 w-[1145px]'>
                    <Tabs
                        defaultActiveKey="1"
                        size={"large"}
                        tabBarStyle={{
                            fontSize: 25,
                            color: "#5f5f5f",
                            backgroundColor: "white",
                            opacity: 100,
                            selectedSegmentTintColor: { color: "5f5f5f" },
                        }}
                    >
                        <Tabs.TabPane
                            tab="Randomized ticket numbers"
                            key="1"
                            style={{ alignSelf: "center", color: "5f5f5f" }}
                        >
                            <div class='text-[22px] text-grey w-[1061px] mt-6 ml-4'>Randomized ticket numbers</div>
                            <div class='text-[16px] text-grey w-[1061px] mt-6 ml-4'>1. On the Fortune Rush page, click the Buy Tickets button at the top of the page (there's another lower on the page also).</div>
                            <div class='my-3 flex justify-center'><img src={image} alt='img' /></div>
                            <div class='text-[16px] text-grey w-[1061px] mt-9 ml-4'>There is a time between rounds where purchasing tickets isn't possible. Just check back after the countdown if that's the case.</div>
                            <div class='my-3 flex justify-center'><img src={ticket} alt='img' /></div>
                            <div class='text-[16px] text-grey w-[1061px] my-9 ml-4'>2. A window will open letting you choose how many ticket to buy. Type how many tickets you'd like to buy into the field. Then you can proceed to choose the ticket number on the next page.
                                <br />3. Before you continue, it's worth considering the bulk purchase discounts. The more tickets you buy, the greater the discount.
                                The discount starts at 2 tickets, and scales all the way to 100 tickets where you will save almost 5% on your purchase (4.95%).
                                <br />
                                4. When you're happy with the number of tickets you want to buy, click View or Edit Numbers.<br/>
                                5. Edit your desired number and then confirm and buy.<br/>
                            </div>
                        </Tabs.TabPane>
                        <Tabs.TabPane
                            tab="Manual ticket numbers"
                            key="2"
                            style={{ alignSelf: "center", color: "black" }}
                        >
                            <div class='text-[22px] text-grey w-[1061px] mt-6 ml-4'>Randomized ticket numbers</div>
                            <div class='text-[16px] text-grey w-[1061px] mt-6 ml-4'>1. On the Fortune Rush page, click the Buy Tickets button at the top of the page (there's another lower on the page also).</div>
                            <div class='my-3 flex justify-center'><img src={image} alt='img' /></div>
                            <div class='text-[16px] text-grey w-[1061px] mt-9 ml-4'>There is a time between rounds where purchasing tickets isn't possible. Just check back after the countdown if that's the case.</div>
                            <div class='my-3 flex justify-center'><img src={ticket} alt='img' /></div>
                            <div class='text-[16px] text-grey w-[1061px] my-9 ml-4'>2. A window will open letting you choose how many ticket to buy. Type how many tickets you'd like to buy into the field. You can also use the preset buttons underneath.
                                <br />3. Before you continue, it's worth considering the bulk purchase discounts. The more tickets you buy, the greater the discount.
                                The discount starts at 2 tickets, and scales all the way to 100 tickets where you will save almost 5% on your purchase (4.95%).
                                <br />  4. When you're happy with the number of tickets you want to buy, click View or Edit Numbers.<br/>
                                5. Edit your desired number and then confirm and buy.<br/></div>
                           
                        
                        </Tabs.TabPane>
                    </Tabs>
                </div>
                <div class='text-[30px] w-[1145px] mt-12'>Viewing your tickets after buying</div>
                <div class='text-[20px] w-[1145px] mt-7'> You can view your tickets after buying them before the round's numbers are drawn.
                </div>
                <div class='text-[20px] w-[1145px] mt-3'>
                    ‌1. To access your profile, click the profile icon located in the top right corner of the navigation bar.<br ></br>
                    2. The profile page displays all of your lottery ticket numbers. Each card provides information for a specific lottery round.<br />
                    3. Each card showcases the round number of the lottery, the ticket number, the status of the lottery round, and the reward earned for that particular lottery.
                </div>
                <div class='my-8 flex justify-center'><img src={pp} alt='pp' /></div>
                <div class='text-[20px] w-[1145px] mt-14 mb-7'>2. A window will open showing all of your tickets and their numbers. It'll also give you the option to buy more tickets if you'd like.</div>
                <div class='my-3 flex justify-center'><img src={edit} alt='edot' /></div>
                <div class='text-[30px] w-[1145px] mt-16'>When the round ends and winning numbers are drawn</div>
                <div class='text-[20px] w-[1145px] mt-7'>When the round has ended and the winning numbers are available on the home page along with the bracket wise count of winners and the  winning amount, you can check your tickets to see if you won.</div>
                <div class='text-[24px] w-[1145px] mt-7 font-medium'>
                    ‌View your tickets 
                </div>
                <div class='text-[20px] w-[1145px] mt-3'>
                    ‌You can view your tickets in the same way you viewed your tickets after buying them.<br />
                    ‌1. Click your Profile icon in top right corner.<br ></br>
                    2. Profile page will show all the ticket numbers bought in each lottery.<br />
                    3. You can claim Prize by clicking See Prize button. If you do not win, not to worry you can play again in the next round.
                </div>
            </div>
        </div>
    )
}

export default HowToPlay;