import won from '../../assets/won.png';
import next from '../../assets/next.png';
// import useWindowSize from './useWindowSize';
// import Confetti from 'react-confetti' 
import { Link, useLocation } from 'react-router-dom';


const Congrats = () => {
    const location = useLocation();
    let data = location.state?.data;
    let amount = location.state?.amount;
    // data = 'Congratulations!';
    // amount = 12.45
    console.log("Amount in congrats.js", amount);
    // width = '100%';
    // height='100%';
    return (

        <div class='flex justify-center'>
            {data === "Congratulations!" ? (
                <div>
                    <div class="flex justify-center">
                        <img class='flex justify-center w-[400px] mt-8' src={won} alt='img' /></div>
                    <div class='flex justify-center text-grey text-[30px]'>Congratulations!</div>
                    <div class='flex justify-center text-[56.23px] text-hover tracking-[-.08em]'>You Won {amount} INEX!</div>
                    <div class=' flex justify-center text-[12px] text-hover'>This is your golden opportunity to win more.</div>
                    <span class='flex justify-center my-5'>
                        <Link to="/Buy" class='w-[200px] h-[33px] bg-binance hover:bg-hover hover:text-binance text-white text-[12px] flex justify-center items-center mr-9'>Let’s Rush Again</Link>
                        <a href="https://test.dex.indexx.ai/" class='w-[200px] h-[33px] bg-binance hover:text-binance hover:bg-hover text-white text-[12px] flex justify-center items-center'>Buy Token</a>
                    </span>
                </div>
            ) :
                (
                    <div>
                        <div class='flex justify-center'>
                            <img class=' w-[350px] mt-16' src={next} alt='img' />
                        </div>
                        <div class='flex justify-center text-grey text-[40px] '>No Prizes to collect</div>
                        <div class='flex justify-center text-[40px] text-binance -mt-3 '>Better luck next time</div>
                        <div class='flex justify-center text-[12px] text-binance'>This is your golden opportunity to win more.</div>
                        <span class='flex justify-center my-5'>
                            <Link to="/Buy" class='w-[200px] h-[33px] bg-binance hover:bg-hover hover:text-binance text-white text-[12px] flex justify-center items-center mr-9'>Let’s Rush Again</Link>
                            <a href="https://test.dex.indexx.ai/" class='w-[200px] h-[33px] bg-binance hover:text-binance hover:bg-hover text-white text-[12px] flex justify-center items-center'>Buy Token</a>
                        </span>
                    </div>
                )}
        </div>

    )
}

export default Congrats;