import purple from '../../assets/blue.png';
import './EditNumbers.css';
import loadingg from '../../assets/Indexx-Fortune.gif'
import { ethers } from "ethers"
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import DisplayMessage from '../Alert/DisplayAlert';
import Indexx from '../../contractsData/Indexx.json';
import IndexxAddress from '../../contractsData/Indexx-address.json';
import IndexxFortuneRushAddress from '../../contractsData/IndexxFortuneRush-address.json';
// import { reverse } from 'dns';
const EditNumbers = ({ indexxFortuneRush, account, provider, switchNetwork }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState("Oops!")
    const [msg, setMsg] = useState("");
    //const [randomNumber, setRandomNumber] = useState(Math.random().toString().substring(2, 8));

    // const [num1, setNum1] = useState(parseInt(randomNumber.toString().charAt(0)));
    // const [num2, setNum2] = useState(parseInt(randomNumber.toString().charAt(1)));
    // const [num3, setNum3] = useState(parseInt(randomNumber.toString().charAt(2)));
    // const [num4, setNum4] = useState(parseInt(randomNumber.toString().charAt(3)));
    // const [num5, setNum5] = useState(parseInt(randomNumber.toString().charAt(4)));
    // const [num6, setNum6] = useState(parseInt(randomNumber.toString().charAt(5)));
    // const [ticketNumber, setTicketNumber] = useState({
    //     num0: parseInt(randomNumber.toString().charAt(0)),
    //     num1: parseInt(randomNumber.toString().charAt(1)),
    //     num2: parseInt(randomNumber.toString().charAt(2)),
    //     num3: parseInt(randomNumber.toString().charAt(3)),
    //     num4: parseInt(randomNumber.toString().charAt(4)),
    //     num5: parseInt(randomNumber.toString().charAt(5)),
    // })
    // const [numOfTickets, SetNumberOfTickets] = useState(JSON.parse(localStorage.getItem('randomNumbers')));
    //const numOfTickets = localStorage.getItem('numOfTickets');
    const [numOfTickets, SetNumberOfTickets] = useState(0);
    const [loading, setLoading] = useState(false);
    const lotteryId = localStorage.getItem('lotteryId');
    const [ticketNumber, setTicketNumber] = useState([]);
    const [cost, setCost] = useState(0);
    const [costInBigNumber, setCostInBigNumber] = useState(0);
    const [isMarked, setIsMarked] = useState(0);
    const [buttonText, setButtonText] = useState("");
    const [navigation, setNavigation] = useState("");
    const navigate = useNavigate();

    // let isMarked = 0;
    function extractErrorCode(str) {

        console.log("in extract", str);
        setNavigation("");
        setButtonText("Ok");
        let ans = "An error occured";
        let firstOccurence, secondOccurence;
        let strmsg = str.toString();
        if (strmsg.includes("is not a function")) {
            ans = "Kindly connect your wallet again";
        }
        else if (strmsg.includes('Nonce too high')) {
            console.log("Nonce too high");
            ans = '\t\tNonce is too High\n Reset your acc using: \n settings-> Advanced-> Reset your account';
        }
        else {
            str = JSON.stringify(str);
            console.log(str)
            if (str.includes("transfer amount exceeds balance")) {
                ans = "You do not have enough INEX tokens. Kindly buy INEX tokens to purchase fortune daily ticket!"
                setNavigation("https://test.dex.indexx.ai/");
                setButtonText("Buy INEX Token");

            } else if (str.includes("insufficient allowance")) {
                ans = "We apologise for the network error. Kindly try again."
                setButtonText("Retry");
            }
            else if (str.includes("NetworkError when attempting to fetch resource")) {
                ans = "Network Error: Binance Smart Chain Network unavailable.\n Try again later";
                setButtonText("Go to Home Page");
                setNavigation("/");
            } else if (str.includes("MetaMask is having trouble connecting to the network")) {
                ans = "MetaMask is having trouble connecting to the network.\n Try again later";
                setButtonText("Go to Home Page");
                setNavigation("/");
            } else if (str.includes("user rejected transaction")) {
                ans = "We are sorry that you had to reject the transaction. Don't miss out on your chance to be a winner. Hurry up to buy the ticket!!"
                setButtonText("Buy Ticket");
                console.log("Navigaiton: ", navigation);

            }
            else if (str.includes("___")) {
                const delimiter = '___'; //Replace it with the delimiter you used in the Solidity Contract.
                firstOccurence = str.indexOf(delimiter);
                secondOccurence = str.indexOf(delimiter, firstOccurence + 1);
                if (secondOccurence === -1);
                else {
                    ans = str.substring(firstOccurence + delimiter.length, secondOccurence);
                    console.log(ans.includes("Come back again"), ans, typeof (ans), "....");
                    if (ans.includes("Come back again")) {
                        setButtonText("Go to Home Page");
                        setNavigation("/");
                    }
                    // setNavigation("/");
                    // setButtonText("Go to Home Page");
                }
            } else {

                var j = JSON.parse(str);
                if (j.reason) {
                    var reason = j["reason"].substring(27);
                    console.log(reason);
                    if (reason !== "")
                        ans = reason;
                } else {
                    ans = "An error occured. Try again later."
                }
            }
        }
        setIsModalOpen(true);
        setMsg(ans);
        setTitle("Oops!");


        console.log("Erorr: in edit newww ", ans, navigation);
    }
    const loadCost = async (e) => {
        // if (lotteryId === 0) {
        //     try {
        //         let lotteryId = await indexxFortuneRush.currentLotteryId();
        //         lotteryId = parseInt(lotteryId.toString(), 10)
        //         setLotteryId(lotteryId);
        //     } catch (err) {
        //         // extractErrorCode(err);
        //         console.log(err);
        //     }
        // }

        SetNumberOfTickets(localStorage.getItem('numOfTickets'));
        // if (isMarked === 0) {
        //     setTicketNumber(JSON.parse(localStorage.getItem('randomNumbers')));
        //     isMarked = 1;
        // }
        if (cost === 0) {
            try {
                let details = await indexxFortuneRush.viewLottery(lotteryId);
                let cost = details[3];
                // console.log("Cost: ", cost);
                // console.log("disc div", details[4]);
                cost = await indexxFortuneRush.calculateTotalPriceForBulkTickets(details[4], details[3], numOfTickets);
                setCostInBigNumber(cost);
                cost = parseInt(cost.toString(), 10);
                console.log("cost: ", cost);
                // cost = cost / 1e18
                setCost(cost);
            } catch (error) {
                // extractErrorCode(error);
                console.log(error);
            }
        }

    }

    const handleRandomize = async (e) => {
        // isMarked = 1;
        setIsMarked(1);
        console.log("called randomize:");
        try {
            // loadCost();
            // console.log("Contract: ", indexxFortuneRush);
            if (!indexxFortuneRush.signer) {
                // setIsModalOpen(true);
                //displayMessage('Oops', 'Please connect your metamask wallet before buying ticket.', 0);
                setMsg('Please connect your metamask wallet before buying ticket.');
                setTitle('Oops!');
                setIsModalOpen(true);
                return;
            }
            // const temp = Math.random() * 10 ** 10;
            // const randomNumber = Math.floor(temp) % (10 ** 6);
            // const temp = Math.random().toString();
            // const randomNumber = temp.substring(2, 8);
            // // console.log("Random Number:", randomNumber, temp);
            // setRandomNumber(randomNumber);
            // setUserTickets();
            // setTicketNumber({
            //     num0: randomNumber.charAt(0),
            //     num1: randomNumber.charAt(1),
            //     num2: randomNumber.charAt(2),
            //     num3: randomNumber.charAt(3),
            //     num4: randomNumber.charAt(4),
            //     num5: randomNumber.charAt(5),
            // })

            // let randomNum = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
            // let inputFields = {};
            // for (let i = 0; i < 6; i++) {
            //   inputFields[`num${i}`] = randomNum[i];
            // }
            // setTicketNumber(inputFields);
            // setUserTickets();

            console.log('I am here', localStorage.getItem('numOfTickets'));
            let inputFields = [];
            for (let i = 0; i < localStorage.getItem('numOfTickets'); i++) {
                let randomNum = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
                inputFields.push({
                    id: i,
                    number: randomNum
                });
            }
            console.log(inputFields)
            setTicketNumber(inputFields);
            // setNum1(parseInt(randomNumber.toString().charAt(0)));
            // setNum2(parseInt(randomNumber.toString().charAt(1)));
            // setNum3(parseInt(randomNumber.toString().charAt(2)));
            // setNum4(parseInt(randomNumber.toString().charAt(3)));
            // setNum5(parseInt(randomNumber.toString().charAt(4)));
            // setNum6(parseInt(randomNumber.toString().charAt(5)));
            // display();
        } catch (err) {
            console.log(err);
        }
    }
    const isTicketAlreadyUsed = async (listOfTicketNumbers) => {
        let alreadyUsed = [];
        for (const key in listOfTicketNumbers) {
            console.log("key: ", listOfTicketNumbers[key]);
            const isUsed = await indexxFortuneRush.isTicketNumberUnique(lotteryId, listOfTicketNumbers[key]);
            console.log(listOfTicketNumbers[key], isUsed);
            if (isUsed)
                alreadyUsed.push(listOfTicketNumbers[key].substring(1).split('').reverse().join(''));
        }
        console.log("Already used tickets: ", alreadyUsed)
        return alreadyUsed;
    }
    const handleBuyTicket = async (e) => {
        if (!indexxFortuneRush.signer) {
            // localStorage.clear();
            // setIsModalOpen(true);
            //displayMessage('Oops!', 'Please connect your metamask wallet before buying ticket.', 0);
            setMsg('Please connect your metamask wallet before buying ticket.');
            setTitle('Oops!');
            setIsModalOpen(true);
            return;
        }
        try {
            let x = await switchNetwork();
            while (x === 0) {
                setMsg("We are currently available on BSC test network only. Kindly switch to Binance Smart Chain (BSC) test network.");
                setTitle("Oops!");
                setIsModalOpen(true);
                x = await switchNetwork();
                // return;
            }
        } catch (err) {

        }
        setLoading(true);
        try {
            const x = await switchNetwork();
            console.log(x);
            if (x === 0) {
                setLoading(false);
                return;
            }

        } catch (err) {
            console.log(err);
            setLoading(false);
            return;


        }

        console.log(ticketNumber)
        const regexp = /^[0-9\b]+$/;
        // console.log("Changed: check if number",inputFields[i],regexp.test(inputFields[i].number));


        // console.log("Number of tickets ", numOfTickets);
        // console.log("Cost ", numOfTickets);
        // let stringRand = ticketNumber.num0 + ticketNumber.num1 + ticketNumber.num2 + ticketNumber.num3 + ticketNumber.num4 + ticketNumber.num5;
        // stringRand = '1' + stringRand.split('').reverse().join('');
        // console.log(stringRand);

        let listOfTicketNumbers = [];
        for (let i = 0; i < ticketNumber.length; i++) {
            if (!regexp.test(ticketNumber[i].number)) {
                console.log("Changed: check if number", ticketNumber[i], regexp.test(ticketNumber[i].number));

                setTitle("Oops!");
                setMsg("Kindly enter all the 6 digits");
                setIsModalOpen(true);
                setLoading(false);

                return;
            }
            listOfTicketNumbers.push('1' + ticketNumber[i].number.split('').reverse().join(''));
        }
        //listOfTicketNumbers = [parseInt(stringRand, 10)];
        console.log(listOfTicketNumbers);
        const alreadyUsed = await isTicketAlreadyUsed(listOfTicketNumbers);
        if (alreadyUsed.length !== 0) {
            setTitle("Oops!");
            setIsModalOpen(true);
            setLoading(false);
            setMsg("Ticket number has been used already. Kindly change the ticket number: " + alreadyUsed);
            return;
        }



        // let lotteryId = await indexxFortuneRush.currentLotteryId();

        // lotteryId = parseInt(lotteryId.toString(), 16)
        // let txn;
        try {
            var indexxContract = new ethers.Contract(IndexxAddress.address, Indexx.abi, provider.getSigner());
            // txn = await indexxContract.approve(account, costInBigNumber);

            // approving the inex
            await (await indexxContract.approve(IndexxFortuneRushAddress.address, costInBigNumber)).wait()
            // const allowance = await indexxContract.allowance(account, IndexxFortuneRushAddress.address);
            // console.log("Approved",allowance);
            console.log("l to buy: ", lotteryId);

            await (await indexxFortuneRush.buyTickets(lotteryId, listOfTicketNumbers)).wait()

            // setIsModalOpen3(true);
            setLoading(false);
            //displayMessage("Hurray!", "Successfully bought the ticket", 1);
            navigate('/successfully-bought-ticket');
            // setMsg("Successfully bought the ticket");
            // setTitle("Hurray!");
            // setIsModalOpen(true);

        } catch (err) {
            extractErrorCode(err);
            setLoading(false);

        }

    }

    useEffect(() => {
        const interval = setInterval(() => {

            if (indexxFortuneRush.signer) {
                // loadInfo();
                // console.log("is marked:",isMarked);
                if (isMarked === 0)
                    handleRandomize();
                loadCost();

            }



            // console.log('I am here', localStorage.getItem('numOfTickets'));
            // let inputFields = [];
            // for (let i = 0; i < localStorage.getItem('numOfTickets'); i++) {
            //     let randomNum = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
            //     inputFields.push({
            //         id: i,
            //         number: randomNum
            //     });
            // }
            // console.log(inputFields)
            // setTicketNumber(inputFields);
        }, 2000);

        return () => clearInterval(interval);
    })

    const handleBackspace = (e, i, j) => {
        if (e.key === 'Backspace') {
            onChange(null, i, j)
        }
        else if (e.key >= '0' && e.key <= '9') {
            onChange(e, i, j);
        } else {
            // Handle other types of keys
            // setTitle("Oops!");
            // setMsg("Enter only numeric values.");
            // setIsModalOpen(true);
            // setLoading(false);
        }
    }

    const onChange = (e, i, j) => {
        if (e === null) {
            let inputFields = [...ticketNumber];
            let newNumber = Array.from(inputFields[i].number);
            console.log(newNumber)
            newNumber[j] = ' ';
            inputFields[i].number = newNumber.join('');
            setTicketNumber(inputFields);
            return;
        } else {
            //setTicketNumber({ ...ticketNumber, [e.target.name]: e.target.value });

            //const handleChange = (e, i, j) => {
            let inputFields = [...ticketNumber];
            let newNumber = inputFields[i].number.split('');
            newNumber[j] = e.key;

            inputFields[i].number = newNumber.join('');
            const regexp = /^[0-9\b]+$/;
            console.log("Changed: check if number: ", e.key, inputFields[i].number, regexp.test(inputFields[i].number));
            if (!regexp.test(inputFields[i].number)) {
                setTitle("Oops!");
                setMsg("Enter only numeric values");
                setIsModalOpen(true);
                setLoading(false);
                return;
            }
            setTicketNumber(inputFields);
        }

    };
    return (
        <>
            <div className='flex justify-center'>
                <div class='mb-7 w-24'><img src={purple} alt='purpple' ></img></div>
            </div>
            <div className='flex justify-center'>
                <div class='w-[670px] border border-grey border-opacity-40 rounded-sm p-8 text-grey'>
                    <div class='border-b border-grey border-opacity-20 pb-6'>
                        {
                            loading ? (
                                <Link> {"<"} Edit Numbers </Link>

                            ) : (
                                <Link to='/Buy'> {"<"} Edit Numbers </Link>
                            )
                        }
                    </div>
                    <div class='pt-6'>
                        <span>Round Number:</span>
                        <span className='inline-flex ml-[415px]'>
                            {lotteryId ? (lotteryId) : (
                                <img src={loadingg}
                                    alt='img'
                                    visible={true}
                                    height="20"
                                    width="30"
                                />)}
                        </span>
                    </div>
                    <div class='pt-6'>
                        <span>Total Cost:</span>
                        <span className='inline-flex ml-[415px]'>
                            {cost ? (cost / 1e18) : (<img src={loadingg}
                                alt='img'
                                visible={true}
                                height="20"
                                width="30"
                            />)} INEX
                        </span>
                    </div>
                    <div class='py-4 text-xs'>Numbers are randomized, with no duplicates among your tickets. Tap a number to edit it. Available digits: 0-9</div>
                    <div class='flex justify-center'>
                        <button onClick={handleRandomize} disabled={loading} class='border border-binance w-[601px] text-binance hover:text-hover hover:border-hover rounded-full py-2 my-5'>Randomize</button>
                    </div>
                    <br />
                    {
                        Array.from({ length: localStorage.getItem('numOfTickets') }, (_, i) => (
                            <>
                                <div class='flex justify-center border-b border-grey border-opacity-0 pb-8'>
                                    <div class='border border-[#5f5f5f] w-[601px] text-[#5f5f5f] rounded-full py-2  flex justify-center text-sm font-medium' key={i}>

                                        {Array.from({ length: 6 }, (_, j) => (
                                            <span class='px-9'>
                                                <input className='input w-[10px]' maxlength="1"  disabled={loading}
                                                    //onChange={(e) => onChange(e, i, j)}
                                                    onKeyDown={(e) => handleBackspace(e, i, j)}
                                                    name={`num${j}`} value={ticketNumber[i] ? ticketNumber[i].number[j] : ''} />
                                            </span>
                                        ))}

                                        {/* <span class='px-9'>
                                            <input className='input w-[10px]' maxlength="1" onChange={onChange} name='num0' value={ticketNumber.num0} />
                                        </span>
                                        <span class='px-9'>
                                            <input className='input w-[10px]' maxlength="1" onChange={onChange} name='num1' value={ticketNumber.num1} />
                                        </span>
                                        <span class='px-9'>
                                            <input className='input w-[10px]' maxlength="1" onChange={onChange} name='num2' value={ticketNumber.num2} />
                                        </span>
                                        <span class='px-9'>
                                            <input className='input w-[10px]' maxlength="1" onChange={onChange} name='num3' value={ticketNumber.num3} />
                                        </span>
                                        <span class='px-9'>
                                            <input className='input w-[10px]' maxlength="1" onChange={onChange} name='num4' value={ticketNumber.num4} />
                                        </span>
                                        <span class='px-9'>
                                            <input className='input w-[10px]' maxlength="1" onChange={onChange} name='num5' value={ticketNumber.num5} />
                                        </span> */}


                                        {/* <button onClick={handleRandomize} class='border border-[#7701FF] w-[601px] text-[#7701FF] hover:text-hover hover:border-hover rounded-full py-2 my-5'>Randomize</button> */}
                                    </div>
                                </div>
                                <br /></>
                        ))
                    }
                    <div class=" flex justify-center md:text-sm text-xs mt-12">
                        <button onClick={handleBuyTicket} disabled={loading} loadings={loading} class='bg-binance hover:bg-hover hover:text-[#3ABFF8] py-3 w-[601px] rounded-sm text-whitebg font-medium'>
                            Confirm and Buy
                        </button>
                    </div>
                    <div>
                        <center>
                            {
                                loading ? (
                                    <img src={loadingg}
                                        alt='img'
                                        visible={true}
                                        height="80"
                                        width="80"
                                    />
                                ) : null}
                            {
                                //</a>
                            }
                        </center>
                    </div>
                    {/* <Link to='/Buy' class="flex justify-center py-7 text-grey hover:text-hover">
                        {'<'} Go Back
                    </Link> */}
                    {
                        (loading) ? (
                            <Link class="flex justify-center py-7 text-binance hover:text-hover">
                                {'<'} Go Back
                            </Link>
                        ) : (
                            <Link to='/Buy' class="flex justify-center py-7 text-binance hover:text-hover">
                                {'<'} Go Back
                            </Link>
                        )
                    }
                </div>
            </div>

            <DisplayMessage onClose={() => setIsModalOpen(false)} isVisible={isModalOpen} title={title} message={msg} navigation={navigation} buttonText={buttonText} />

        </>
    );
}


export default EditNumbers;