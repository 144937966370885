import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import img from "../../assets/cross.png";
import purple from '../../assets/blue.png';
//import displayMessage from "../Alert/DisplayAlert";
// import extractErrorCode from "../Alert/ErrorMessage";
import loadingg from '../../assets/Indexx-Fortune.gif'
import DisplayMessage from "../Alert/DisplayAlert";


const Buy = ({ web3Handler, account, indexxFortuneRush, switchNetwork }) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [title, setTitle] = useState("Oops!")
    const [msg, setMsg] = useState("");
    const [data, setData] = useState({
        lotteryId: '',
        ticketNumber: ''
    });
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    // const [maximum, setMax] = useState();
    function extractErrorCode(str) {

        console.log("in extract", str);

        let ans = "An error occured";
        let firstOccurence, secondOccurence;
        let strmsg = str.toString();
        if (strmsg.includes("is not a function")) {
            ans = "Kindly connect your wallet again";
        }
        else if (strmsg.includes('Nonce too high')) {
            console.log("Nonce too high");
            ans = '\t\tNonce is too High\n Reset your acc using: \n settings-> Advanced-> Reset your account';
        }
        else {
            str = JSON.stringify(str);
            console.log(str)
            if (str.includes("NetworkError when attempting to fetch resource")) {
                ans = "Network Error: Binance Smart Chain Network unavailable.\n Reload and try again later";

            } else if (str.includes("MetaMask is having trouble connecting to the network")) {
                ans = "MetaMask is having trouble connecting to the network.\n Try again later";

            }
            else if (str.includes("___")) {
                const delimiter = '___'; //Replace it with the delimiter you used in the Solidity Contract.
                firstOccurence = str.indexOf(delimiter);
                secondOccurence = str.indexOf(delimiter, firstOccurence + 1);
                if (secondOccurence === -1);
                else {
                    ans = str.substring(firstOccurence + delimiter.length, secondOccurence);

                }
            } else {

                var j = JSON.parse(str);
                if (j.reason) {
                    var reason = j["reason"].substring(27);
                    console.log(reason);
                    if (reason !== "")
                        ans = reason;

                } else {
                    ans = 'An error occured. Kindly retry.'
                }
            }
        }
        setIsModalOpen(true);
        setMsg(ans);
        setTitle("Oops!");
        console.log("Erorr: in edit new ", ans);
    }
    const handleEdit = async (e) => {
        if (indexxFortuneRush.signer === null) {
            setMsg("Connect Wallet Again");
            //displayMessage("Oops!", "Connect Wallet Again", 0);
            setTitle("Oops!");
            setIsModalOpen(true);
            return;
        }
        try {
            let x = await switchNetwork();
            while (x === 0) {
                setMsg("We are currently available on BSC test network only. Kindly switch to Binance Smart Chain (BSC) test network.");
                setTitle("Oops!");
                setIsModalOpen(true);
                x = await switchNetwork();
                // return;
            }
        } catch (err) {

        }

        console.log("Acc in Buy.js: ", account, data.ticketNumber, indexxFortuneRush);
        if (account !== null) {
            setLoading(true);
            data.ticketNumber = data.ticketNumber.trim();
            if (data.ticketNumber.length === 0) {
                // setIsModalOpen(true)
                //displayMessage('Oops!', "Kindly enter number of tickets you want to purchase", 0);
                setTitle("Oops!");
                setMsg("Kindly enter number of tickets you want to purchase");
                setIsModalOpen(true);
                setLoading(false);
                return;
            }
            const regexp = /^[0-9\b]+$/;
            if (!regexp.test(data.ticketNumber)) {
                //displayMessage("Oops", "Enter only numeric values", 0);
                setTitle("Oops!");
                setMsg("Enter only numeric values");
                setIsModalOpen(true);
                setLoading(false);
                return;
            }
            const ticketNumber = parseInt(data.ticketNumber);
            localStorage.setItem("numOfTickets", ticketNumber);
            let numbers = [];
            for (let i = 0; i < ticketNumber; i++) {
                let randomNum = Math.floor(Math.random() * Math.pow(10, 6)).toString().padStart(6, '0');
                numbers.push(
                    {
                        id: i,
                        number: randomNum
                    });
            }
            localStorage.setItem("randomNumbers", JSON.stringify(numbers))
            let max;
            try {
                max = await indexxFortuneRush.maxNumberTicketsPerBuyOrClaim();
                console.log("max", max);
                localStorage.setItem('maxTicket', max);
                if (ticketNumber > 0 && ticketNumber <= max) {
                    setLoading(false);
                    navigate('/View-Edit-Numbers');
                    // displayMessage("Hurray", "Will be navigated", 1);

                } else {
                    // setIsModalOpen2(true);
                    // setMax(max);
                    // swal("", "Please enter the number between 1 and " + max)
                    setTitle("Oops!");
                    setMsg("Please enter the number between 1 and " + max);
                    setLoading(false);
                    setIsModalOpen(true);
                    //displayMessage("Oops!", "Please enter the number between 1 and " + max, 0);
                }
            } catch (error) {
                extractErrorCode(error);
                setLoading(false);
            }


        } else {
            // console.log(account);
            // setIsModalOpen3(true);
            //displayMessage('Oops', 'Please connect your metamask account before buying ticket.', 0);
            setTitle("Oops!");
            setMsg('Please connect your metamask account before buying ticket.');
            setIsModalOpen(true);
            setLoading(false);
        }
    }
    const onChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    // useEffect(() => {
    //     const interval = setInterval(() => {
    //         console.log("int");
    //         switchNetwork();
    //             // setMsg("We are currently available on BSC test network only. Kindly switch to Binance Smart Chain (BSC) test network.");
    //             // setTitle("Oops!");
    //             // setIsModalOpen(true);
    //         // }
    //     }, 5000);
    //     return () => clearInterval(interval);
    //     // eslint-disable-next-line
    // }, []);
    return (
        <div class="container py-1 flex flex-col items-center pt-12 content-center">
            <div class='mb-7 w-24'><img src={purple} alt='purpple' ></img></div>
            <div class="grid grid-cols-3 gap-x-12  border border-grey border-opacity-25 rounded-sm md:w-[670px] w-[340px]">
                <div class="col-start-1 col-span-2 font-medium px-8 pb-4 pt-7 text-grey md:text-sm text-xs">Buy Tickets</div>
                <div class="col-start-3 col-span-full pb-4 pt-7 px-8 text-grey text-xs flex justify-end">
                    <Link to="/"><img src={img} alt='tickets' class='inline' /></Link>
                </div>

                <div class="col-start-1 col-span-1 font-normal pb-4 pl-8 text-grey md:text-sm text-xs ">Buy:</div>
                <div class="col-start-2 col-span-full font-bold pb-4 pr-8 text-grey md:text-sm text-xs flex justify-end">Tickets</div>
                <div class="col-start-1 col-span-full flex justify-center p-4">

                    <input onChange={onChange} class="appearance-none border border-grey border-opacity-20 rounded-sm w-[601px] py-2 md:text-sm text-xs px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="username" name='ticketNumber' type="text" placeholder="Number of tickets" />
                </div>
                {
                    <div class="col-start-1 col-span-2 font-normal py-4 px-8 text-grey md:text-sm text-xs ">Lottery Round  {localStorage.getItem('lotteryId')} </div>
                    // <div class="col-start-1 col-span-2 font-normal py-4 px-8 text-grey md:text-sm text-xs ">You pay :</div>
                }
                <div class="col-start-1 col-span-3 p-4 flex justify-center md:text-sm text-xs">
                    {
                        //     <button class='bg-binance hover:text-[#3ABFF8] hover:bg-hover py-5 w-[601px] text-whitebg font-medium'>
                        //     Buy Instantly
                        // </button>
                    }
                </div>
                <div class="col-start-1 col-span-3 px-4 pb-4 flex justify-center md:text-sm text-xs">
                    {
                        // <a href="">
                    }
                    <button onClick={handleEdit} disabled={loading} class='hover:text-[#3ABFF8] bg-binance hover:bg-hover py-3 w-[601px] text-whitebg font-medium'>
                        View/ Edit Numbers {">"}
                    </button>
                </div>
                <div class="col-start-1 col-span-3 px-4 pb-4 flex justify-center md:text-sm text-xs">

                    {
                        loading ? (
                            <center>
                                <img
                                    visible={true}
                                    height="80"
                                    width="80"
                                    src={loadingg}
                                    alt='img'
                                />
                            </center>
                        ) : null}
                    {
                        //</a>
                    }
                </div>


                <div class="col-start-1 col-span-3 px-8 pt-4 pb-8 flex justify-center md:text-sm text-xs text-grey">
                    Prices are set before each round starts. You can see your total payable amount on the next page.
                </div>

            </div>

            <DisplayMessage title={title} isVisible={isModalOpen} message={msg} onClose={() => setIsModalOpen(false)} />
        </div>
    );
}

export default Buy;